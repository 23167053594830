<template>
  <div
    v-loading="loading"
    class="account-page"
  >
    <div class="title">
      <el-row>
        <el-col
          :xl="12"
          :lg="12"
          :md="12"
          :sm="24"
          :xs="24"
        >
          <h2>Account settings</h2>
        </el-col>
        <el-col
          :xl="12"
          :lg="12"
          :md="12"
          :sm="24"
          :xs="24"
          class="text-right"
        >
          <button
            class="fgh-button fgh-button--link"
            @click.prevent="logout"
          >
            Logout
          </button>
        </el-col>
      </el-row>
    </div>
    <div class="settings-account-content">
      <el-form
        id="settingsForm"
        ref="settingsForm"
        :model="settingsForm"
        :rules="rules"
      >
        <el-row>
          <el-col
            :xl="24"
            :lg="24"
            :md="24"
            :sm="24"
            :xs="24"
            class="form-fields"
          >
            <div class="d-flex change-img">
              <el-col
                :xl="3"
                :lg="5"
                :md="5"
                :sm="24"
                :xs="24"
              >
                <div
                  v-if="!changeImg"
                  class="user-img"
                  :style="{ 'background-image' : `url( ${ item.image } )` }"
                ></div>
                <div
                  v-if="changeImg"
                  class="user-img"
                  :style="{ 'background-image' : `url(${ item.image } )` }"
                ></div>
              </el-col>
              <el-col
                :xl="21"
                :lg="19"
                :md="19"
                :sm="24"
                :xs="24"
              >
                <button
                  type="button"
                  class="fgh-button fgh-button--primary-outline add-img"
                >
                  Add image
                  <input
                    type="file"
                    @change="onFileChange($event)"
                  />
                </button>
              </el-col>
            </div>
            <div class="details">
              <div class="title">
                <span>Your details</span>
              </div>
              <el-row :gutter="30">
                <el-col
                  :xl="12"
                  :lg="12"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <el-form-item
                    label="First name"
                    prop="firstName"
                    class="is-no-asterisk"
                  >
                    <el-input
                      v-model="settingsForm.firstName"
                      placeholder="First name"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col
                  :xl="12"
                  :lg="12"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <el-form-item
                    label="Surname"
                    prop="surname"
                    class="is-no-asterisk"
                  >
                    <el-input
                      v-model="settingsForm.surname"
                      placeholder="Surname"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col
                  :xl="12"
                  :lg="12"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <el-form-item
                    label="Personal email address"
                    prop="email"
                    class="is-no-asterisk"
                  >
                    <el-input
                      v-model="settingsForm.email"
                      type="email"
                      placeholder="Email address"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col
                  :xl="12"
                  :lg="12"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <el-form-item
                    label="Choose a team you belong to"
                    prop="team"
                    class="is-no-asterisk"
                  >
                    <el-select
                      v-model="settingsForm.team"
                      placeholder="Select"
                      @change="fetchChildTeams"
                    >
                      <el-option
                        v-for="team in companyTeams"
                        :key="team.id"
                        :label="team.name"
                        :value="team.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col
                  :offset="12"
                  :xl="12"
                  :lg="12"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <el-form-item
                    v-show="companyChildTeams.length"
                    label="Choose a business area you belong to"
                    prop="subteam"
                  >
                    <el-select
                      v-model="settingsForm.subteam"
                      placeholder="Select"
                    >
                      <el-option
                        v-for="subteam in companyChildTeams[0]"
                        :key="subteam.id"
                        :label="subteam.name"
                        :value="subteam.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col
                  :xl="12"
                  :lg="12"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <el-form-item
                    label="County"
                    prop="location"
                    class="is-no-asterisk"
                  >
                    <el-select
                      v-model="settingsForm.location"
                      placeholder="Select"
                    >
                      <el-option
                        v-for="location in companyLocations"
                        :key="location.id"
                        :label="location.name"
                        :value="location.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col
                  :xl="24"
                  :lg="24"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <div class="title">
                    <span>Update password</span>
                  </div>
                </el-col>
                <!-- <el-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
                                    <el-form-item label="Current password" prop="current_password">
                                        <el-input type="password" v-model="settingsForm.current_password" auto-complete="off" placeholder="Current password"></el-input>
                                    </el-form-item>
                                </el-col> -->
                <el-col
                  :xl="12"
                  :lg="12"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <el-form-item
                    label="New password (min 6 characters)"
                    prop="password"
                  >
                    <el-input
                      v-model="settingsForm.password"
                      type="password"
                      auto-complete="off"
                      placeholder="Create a password"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col
                  :xl="12"
                  :lg="12"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <el-form-item
                    label="Confirm new password"
                    prop="password_confirmation"
                  >
                    <el-input
                      v-model="settingsForm.password_confirmation"
                      type="password"
                      auto-complete="off"
                      placeholder="Confirm password"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col
            :xl="24"
            class="save-updates-btn"
          >
            <button
              type="submit"
              class="fgh-button lg fgh-button--primary"
              :disabled="loading"
              @click.prevent="updateProfile"
            >
              Save updates
            </button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import authApi from '@/modules/auth/services/auth.api'
import authRules from '@/modules/auth/services/auth.rules'
import accountApi from '@/modules/account/services/account.api'

export default {
  name: "AccountPage",

  data() {
    const validateSubTeam = (rule, value, callback) => {
      const team = this.companyTeams.find(team => team.id == this.settingsForm.team)
      if (!team || team.child_teams_count == 0) {
        callback()
      } else if (this.settingsForm.subteam == '') {
        callback(new Error('Please input your business area'));
      } else {
        callback()
      }
    };
    const validateNewPasswordConfirmation = (rule, value, callback) => {
      const isRequired = this.settingsForm.password != ''

      if (isRequired && value !== this.settingsForm.password) {
        callback(new Error('New passwords don\'t match!'));
      } else {
        callback();
      }
    };
    return {
      settingsForm: {
        email: '',
        current_password: '',
        password: '',
        password_confirmation: '',
      },
      rules: {
        firstName: [
          { required: true, message: 'The first name field is required.' },
        ],
        surname: [
          { required: true, message: 'The surname field is required.' }
        ],
        email: [
          { validator: authRules.validateEmailAddress, required: true }
        ],
        team: [
          { required: true, message: 'The team field is required.' }
        ],
        subteam: [
          { validator: validateSubTeam, required: true }
        ],
        location: [
          { required: true, message: 'The county field is required.' }
        ],
        password: [
          { required: false, min: 6, message: 'Your new password contains less than 6 characters', trigger: 'change' }
        ],
        password_confirmation: [
          { validator: validateNewPasswordConfirmation }
        ]
      },
      item: {
        image: this.$store.state.avatar
      },
      user: {
        firstName: this.$store.state.firstName,
        surname: this.$store.state.lastName,
        email: this.$store.state.email,
        team: '',
        subteam: '',
        location: ''
      },
      changeImg: false,
      loading: false,
      companyLocations: [],
      companyTeams: [],
      companyChildTeams: []
    }
  },

  created() {
    this.settingsForm = {...this.settingsForm, ...this.user}

    this.getLocations()
    this.getTeams()
  },

  methods: {
    getLocations() {
      this.loading = true
      authApi
        .companyLocations()
        .then(response => {
          this.loading = false
          this.companyLocations = response.data.data
          this.user.location = this.$store.state.location
          this.settingsForm.location = this.$store.state.location
        })
    },
    getTeams() {
      this.loading = true
      authApi
        .companyTeams()
        .then(response => {
          this.loading = false
          function compare(a, b) {
            if (a.name < b.name)
              return -1;
            if (a.name > b.name)
              return 1;
            return 0;
          }
          this.companyTeams = response.data.data.sort(compare);

          if (this.$store.state.teamParentId) {
            this.settingsForm.team =  this.$store.state.teamParentId
            this.settingsForm.subteam = this.$store.state.team
            this.fetchChildTeams()
          } else {
            this.settingsForm.team =  this.$store.state.team
          }
        })
    },
    fetchChildTeams() {
      const team = this.companyTeams.find(team => team.id == this.settingsForm.team)
      if (!team || team.child_teams_count == 0) {
        this.companyChildTeams = []
        this.settingsForm.subteam = ''
        return
      }
      authApi
        .companyTeams({
          parent_team_id: this.settingsForm.team
        })
        .then(response => {
          this.companyChildTeams.push(
            response.data.data
          )
        })
    },
    onFileChange(event) {
      this.loading = true;
      let files = event.target.files || event.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(this.item, files[0]);
    },
    createImage(item, file) {
      let image = new Image();
      let reader = new FileReader();

      reader.onload = (e) => {
        item.image = e.target.result;
      };
      this.changeImg = true;
      reader.readAsDataURL(file);

      let formData = new FormData();
      formData.append("avatar", file);

      accountApi
        .updateProfileAvatar(formData)
        .then(response => {
          this.loading = false
          this.$store.commit('CHANGE_AVATAR', response.data.avatar)
          this.$notify.success({
            title: 'Success',
            message: 'Your avatar has has updated successfully.'
          })
        })
    },
    updateProfile() {
      this.$refs.settingsForm.validate((valid) => {
        if (valid) {
          this.loading = true
          accountApi
            .updateProfile({
              first_name: this.settingsForm.firstName,
              last_name: this.settingsForm.surname,
              company_location_id: this.settingsForm.location,
              company_team_id: this.settingsForm.subteam != '' ? this.settingsForm.subteam : this.settingsForm.team,
              password: this.settingsForm.password,
              password_confirmation: this.settingsForm.password_confirmation,
            })
            .then(response => {
              this.loading = false
              this.$store.commit('LOGIN_USER', response.data)
              this.$notify.success({
                title: 'Success',
                message: 'Your profile has been updated successfully.'
              })
            }).catch(error => {
              this.loading = false

              if (error.response && error.response.status == 422 && error.response.data) {
                _.each(error.response.data.errors, function (obj, index) {
                  this.$notify.error({
                    title: 'Error',
                    message: obj[0]
                  })
                })
              } else {
                this.$notify.error({
                  title: 'Error',
                  message: 'An unexpected error occured. Please try again later'
                })
              }
            })
        }
      })
    },
    logout() {
      this.loading = true
      authApi
        .logout(this.$store.state.token)
        .then(() => {
          this.loading = false
          this.$store.commit('LOGOUT_USER')
          setTimeout(()=> {
            this.$router.push('/')
          }, 100)
        })
    }
  }
}
</script>

<style lang="scss">
.account-page {
  background-color: $white-color;
  width: 60%;
  margin: 37px auto 66px auto;
  padding: 24px 30px 33px 30px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05), 0px 0px 21px rgba(0, 0, 0, 0.07);

  .el-loading-mask {
    background-color: rgba(255,255,255,.9);
  }

  .title {
    h2 {
      margin: 0;
      @media (max-width: 842px) {
        text-align: center;
      }
    }
    button {
      @media (max-width: 842px) {
        margin-top: 10px;
      }
    }
  }
  .settings-account-content {
    .change-img {
      margin-top: 41px;
      align-items: center;
      @media (max-width: 425px) {
        display: block;
        overflow: auto;
        text-align: center;
      }
        .user-img {
        background-position: center center;
        background-size: cover;
        position: relative;
        width: 96px;
        height: 96px;
        overflow: hidden;
        border-radius: 50%;
        @media (max-width: 425px) {
          margin: 0 auto;
        }
        img {
          width: auto;
          height: 100%;
          margin-left: -50px;
        }
      }
      .add-img {
        position: relative;
        margin-left: 25px;
        cursor: pointer;
        -webkit-transition: background-color .5s;
        -moz-transition: background-color .5s;
        -ms-transition: background-color .5s;
        -o-transition: background-color .5s;
        transition: background-color .5s;
        @media (max-width: 425px) {
          margin: 10px 0;
        }
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
    .form-fields {
      padding: 0 96px;
      @media (max-width: 1024px) {
        padding: 0;
      }
    }
    .details {
      margin-top: 25px;
      @media (max-width: 320px) {
        .el-col {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
      .title {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 23px;
        margin-top: 13px;
      }
      .el-select {
        width: 100%;
      }
      input {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $border-color;
        font-size: 14px;
        padding-left: 0;
        background: transparent;
        color: $body-text-color;
        &::placeholder {
          color: $input-placeholder-color;
        }
        &:focus {
          border-bottom: 1px solid $primary-color;
        }
      }
      label {
        line-height: 20px;
        color: $body-text-color;
        font-size: 12px;
        &:before {
          content: '';
          margin-right: 0;
        }
      }
      .change-password {
        padding: 0 !important;
        margin-top: 23px;
      }
    }
    .save-updates-btn {
      margin-top: 10px;
      text-align: right;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
}
</style>
